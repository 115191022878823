// Initialize the Swiper-Slider
function initSwiper(selector, breakpoints) {
    new Swiper(selector + ' .swiper-container', {
        // Configuration
        direction: 'horizontal',
        observer: true,
        // Pagination
        pagination: {
            el: selector + ' .swiper-pagination',
            clickable: true,
        },
        // Navigation-Arrows
        navigation: {
            nextEl: selector + ' .swiper-button-next',
            prevEl: selector + ' .swiper-button-prev',
        },
        // Responsive Breakpoints
        breakpoints: breakpoints
    });
}

// Toggles the visibility of the navigation
function toggle_navigation(swiper_wrapper_outer) {
    var pages = swiper_wrapper_outer.find('.navigation .swiper-pagination .swiper-pagination-bullet').length;

    if (pages > 1) {
        swiper_wrapper_outer.removeClass('hide-navigation');
    } else {
        swiper_wrapper_outer.addClass('hide-navigation');
    }
}

// Definition of the Kendo-Widget: kendoSwiper
(function () {
    try {
        var kendo = window.kendo,
            ui = kendo.ui,
            Widget = ui.Widget;

        var Swiper = Widget.extend({            
            init: function (element, options) {
                var that = this;
                var templateContent = $(options.swiperOptions.slideTemplate).html();
                var swiper_wrapper = element.querySelector('.swiper-wrapper'); // Get the Swiper-Wrapper child to insert the slides into

                kendo.ui.Widget.fn.init.call(that, swiper_wrapper, options);
                that.template = kendo.template(templateContent);
                that._initSwiper(element);
            },
            options: {
                name: "Swiper",
                autoBind: true,
            },
            refresh: function (element) {
                var that = this,
                    view = that.swiperData.view(),
                    html = kendo.render(that.template, view);

                that.element.html(html);
            },
            _initSwiper: function (element) {
                var that = this;               

                // Returns the datasource OR creates one if using array or configuration object
                that.swiperOptions = kendo.data.DataSource.create(that.options.swiperOptions);
                that.swiperData = kendo.data.DataSource.create(that.options.swiperData);

                // Bind to the change event to refresh the widget
                that.swiperData.bind("change", function () {
                    that.refresh(element);
                });

                if (that.options.autoBind) {
                    that.swiperData.fetch();
                }

                // Initially hide the navigation
                $(element).addClass('hide-navigation');

                // Get and prepare the parameters to pass
                var breakpoints = that.swiperOptions.options.breakpoints;
                var selector = '#' + element.id;

                // Initialize the Swiper-Slider and pass the parameters
                initSwiper(selector, breakpoints);

                // Toggles the visibility of the navigation
                toggle_navigation($(this));
            },
        });

        ui.plugin(Swiper);
    } catch (e) {
        // kendo.ui not defined
        console.log("kendo.ui not defined");
    }
})(jQuery);

// Window Events
$(window).on('load resize', function () {
    $('.swiper-wrapper-outer').each(function () {
        toggle_navigation($(this));
    });
});

// Document Events
$(document).ready(function () {
    $('.swiper-wrapper-outer').each(function () {
        toggle_navigation($(this));
    });
});